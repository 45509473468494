<header class="header">
  <div *ngIf="showAnnouncement && region !== 'Russian Federation'"
     target="_blank"
     class="header__announcement">
    <b>{{ "support_us_gitcoin" | translate }}</b>
  </div>
  <div class="header__centering">
    <a href="/home"
       title="{{'main_page_link' | translate}}">
      <app-icon
        class="header__logo __hoverable"
        staticSrc="/assets/images/logo-short.svg"
        size="custom"
        customWidth="50px"
        customHeight="50px"
      ></app-icon>
    </a>

    <nav class="header__middle">
      <ul class="navigation-list">
        <li
          class="navigation-list__item">
          <a class="item__link"
             [routerLink]="'/genes'"
             routerLinkActive="__current"
          >{{ "header_genes" | translate }}</a>
        </li>

        <li
          class="navigation-list__item">
          <a class="item__link"
             [routerLink]="'/annotations'"
             routerLinkActive="__current"
          >{{ "header_annotations" | translate }}</a>
        </li>

        <li
          class="navigation-list__item">
          <a class="item__link"
             [routerLink]="'/about'"
             routerLinkActive="__current"
          >{{ "header_help" | translate }}
          </a>
        </li>

        <li
          class="navigation-list__item">
          <a class="item__link"
             [routerLink]="'/download'"
             routerLinkActive="__current"
          >{{ "header_download" | translate }}
          </a>
        </li>
      </ul>
    </nav>

    <nav class="header__right">
      <a
        href="https://donate.stripe.com/00geYbeig6Hn53y6oC"
        class="btn btn--fill btn--purple header__donation-button"
        target="_blank"
      ><span class="fa far fa-heart"></span>&nbsp;{{ 'about_page_donation_button' | translate }}
      </a>
      <app-burger-menu></app-burger-menu>
    </nav>
  </div>
</header>
