<div class="article-modal__header" mat-dialog-title>
  <span>{{ data?.modalData?.title }}</span>
  <span>{{ data?.modalData?.subtitle }}</span>
  <button mat-icon-button
          class="close-button"
          (click)="closeArticleModal()"
          aria-label="Закрыть">
    <span class="fa fal fa-times"></span>
  </button>
</div>
<mat-dialog-content>
  <div class="article-modal__cover">
    <img [src]=data?.modalData?.image?.url?.src
         (error)="imgErrorHandler($event, defaultCover)"
         alt="">
  </div>

  <div class="article-modal__body">
    <article *ngFor="let item of article">
      <span [innerHTML]="item?.text | safe: 'html'"></span>
    </article>
    <div class="article-modal__button-wrap btn-wrap show-more">
      <a class="btn show-more__button"
         href="{{ url }}articles/{{ data?.modalData?.slug }}"
         target="_blank">
        {{ "read_more" | translate }} <span class="fa far fa-external-link"></span>
      </a>
    </div>
  </div>
</mat-dialog-content>
