<div class="bottom-sheet">
  <div class="bottom-sheet__header">
    <ng-container *ngIf="data.title">
      {{ data.title | translate }}
    </ng-container>
    <button mat-icon-button
            class="bottom-sheet__close-button"
            (click)="closeBottomSheet()"
            aria-label="Закрыть">
      <span class="fa fal fa-times"></span>
    </button>
  </div>

  <div class="bottom-sheet__description">
    <article>
      <ng-container
        [ngTemplateOutlet]="data.template"
      ></ng-container>
    </article>
  </div>

</div>
