<div class="common-modal__header" mat-dialog-title>
  {{ data.title | translate }}

  <button mat-icon-button
          class="close-button"
          (click)="closeCommentModal()"
          aria-label="Закрыть">
    <span class="fa fal fa-times"></span>
  </button>
</div>
<mat-dialog-content>
  <div class="common-modal__body">
    <article>
      <ng-container *ngIf="!data.template">
        {{ data.body }}
      </ng-container>
      <ng-container
        [ngTemplateOutlet]="data.template"
        [ngTemplateOutletContext]="{ item: data.body }"
      ></ng-container>
    </article>
  </div>
</mat-dialog-content>
