export enum ICON_SIZE {
  custom = "custom", // custom
  extraSmall = "xs", // 16
  small = "sm", // 20
  regular = "rg", // 24
  medium = "md", // 28
  large = "lg", // 32
}

export type ICON_SIZE_ENUM_TO_TYPE =
  | "custom"
  | "xs"
  | "sm"
  | "rg"
  | "md"
  | "lg";
